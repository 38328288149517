import { Box, Heading, Stack, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { defaultMaxRoomDownloads } from '../../config.js'

import { ButtonLink } from '../ButtonLink.js'
import { Footer } from '../Footer.js'
import { Header } from '../Header.js'
import { SafeContainer } from '../SafeContainer.js'

export const ErrorPage = ({ statusCode }) => {
  const { t } = useTranslation()

  return (
    <SafeContainer maxWidth='4xl'>
      <Header maxWidth='4xl' mb={10} />
      <Stack textAlign='center' spacing={6}>
        {statusCode === 404 && (
          <>
            <Heading as='h1'>{t('errorPage.pageNotFound')}</Heading>
            <Text>
              {t('errorPage.expectedFilesDeleted', {
                maxRoomDownloads: defaultMaxRoomDownloads
              })}{' '}
            </Text>
          </>
        )}
        {statusCode === 500 && (
          <>
            <Heading as='h1'>{t('errorPage.serverError')}</Heading>
            <Text>{t('errorPage.wormholeTeamNotified')}</Text>
          </>
        )}
        {statusCode !== 404 && statusCode !== 500 && (
          <>
            <Heading as='h1'>
              {t('errorPage.error')} ({statusCode})
            </Heading>
            <Text>{t('errorPage.wormholeTeamNotified')}</Text>
          </>
        )}
        <Box>
          <ButtonLink href='/'>{t('errorPage.sendFiles')}</ButtonLink>
        </Box>
      </Stack>
      <Footer maxWidth='4xl' mt={10} mb={5} />
    </SafeContainer>
  )
}
