import NextError from 'next/error'
import { captureException } from '../lib/sentry.js'

import { ErrorPage } from '../components/pages/ErrorPage.js'

export default function MyError ({ statusCode, hasGetInitialPropsRun, err }) {
  if (!hasGetInitialPropsRun && err) {
    // getInitialProps is not called in case of
    // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
    // err via _app.js so it can be captured
    captureException(err)
  }

  // Next.js has built-in support for 404 and 500 pages, but we can't easily
  // localize them because of https://nextjs.org/docs/messages/404-get-initial-props
  // So dispatch from here instead
  return <ErrorPage statusCode={statusCode} />
}

MyError.getInitialProps = async ctx => {
  const errorInitialProps = await NextError.getInitialProps(ctx)

  // Allow the status code to be set by the Express error handler in app.js
  // Malicious users that try to set this value in a query parameter will be
  // unsuccessful because they can't set query values to a number type.
  if (typeof ctx.query.statusCode === 'number') {
    errorInitialProps.statusCode = ctx.query.statusCode
  }

  // Workaround for https://github.com/vercel/next.js/issues/8592, mark when
  // getInitialProps has run
  errorInitialProps.hasGetInitialPropsRun = true

  // Next.js will pass an err on the server if a page's data fetching methods
  // threw or returned a Promise that rejected
  //
  // Running on the client (browser), Next.js will provide an err if:
  //
  //  - a page's `getInitialProps` threw or returned a Promise that rejected
  //  - an exception was thrown somewhere in the React lifecycle (render,
  //    componentDidMount, etc) that was caught by Next.js's React Error
  //    Boundary. Read more about what types of exceptions are caught by Error
  //    Boundaries: https://reactjs.org/docs/error-boundaries.html

  const { err, req } = ctx

  if (err) {
    captureException(err, { req })
  }

  switch (errorInitialProps.statusCode) {
    case 404:
      errorInitialProps.i18nTitle = ['errorPage.pageNotFound']
      break
    case 500:
      errorInitialProps.i18nTitle = ['errorPage.serverError']
      break
    default:
      errorInitialProps.i18nTitle = [
        'errorPage.errorCode',
        { statusCode: errorInitialProps.statusCode }
      ]
      break
  }
  return errorInitialProps
}
